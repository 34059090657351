import CapabilitiesSection from 'components/shared/CapabilitiesSection';
import FeatureSection from 'components/shared/FeatureSection';
import FunctionalitiesSection from 'components/shared/FunctionalitiesSection';
import HeaderButtons from 'components/shared/HeaderButtons';
import ImplementationSection from 'components/shared/ImplementationSection';
import PackagesSection from 'components/shared/PackagesSection';
import PageHeader from 'components/shared/PageHeader';
import QuestionsSection from 'components/shared/QuestionsSection';
import SEO from 'components/shared/SEO';
import homepageData from 'data/homepage';
import React from 'react';

export default function Home() {
   const packages = {
      buttons: [
         {
            text: 'Wybierz Student',
            to: '/contact',
            state: { package: 'Student' },
         },
         {
            text: 'Wybierz Test&Go',
            to: '/contact',
            state: { package: 'Test&Go' },
         },
         {
            text: 'Wybierz Rocket',
            to: '/contact',
            state: { package: 'Rocket' },
         },
      ],
   };
   return (
      <>
         <SEO title="Strona główna" />
         <PageHeader as="header" headerText={homepageData.header}>
            <HeaderButtons />
         </PageHeader>

         <FeatureSection cards={homepageData.featureCards} />
         <QuestionsSection
            title={homepageData.questionTitle}
            clouds={homepageData.questionClouds}
            illustration={homepageData.questionIllustration}
         />
         <CapabilitiesSection cards={homepageData.capabilitiesCards} />
         <FunctionalitiesSection
            title={homepageData.functionalitiesTitle}
            tabs={homepageData.functionalitiesTabs}
         />
         <ImplementationSection title={homepageData.implementationTitle} />
         <PackagesSection packagesLinks={packages} />
      </>
   );
}
