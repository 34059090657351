import React from 'react';
import { colors } from 'variables';

import tile1 from 'assets/images/homepage/image1.png';
import tile1x2 from 'assets/images/homepage/image1@2x.png';
import tile2 from 'assets/images/homepage/image2.png';
import tile2x2 from 'assets/images/homepage/image2@2x.png';
import tile3 from 'assets/images/homepage/image3.png';
import tile3x2 from 'assets/images/homepage/image3@2x.png';

import questionIllustration from 'assets/images/homepage/illustration.svg';

import capabilities_image1 from 'assets/images/homepage/image4.png';
import capabilities_image1x2 from 'assets/images/homepage/image4@2x.png';
import capabilities_image2 from 'assets/images/homepage/image5.png';
import capabilities_image2x2 from 'assets/images/homepage/image5@2x.png';
import capabilities_image3 from 'assets/images/homepage/image6.png';
import capabilities_image3x2 from 'assets/images/homepage/image6@2x.png';

import tab1 from 'assets/images/homepage/grafika1.png';
import tab1x2 from 'assets/images/homepage/grafika1@2x.png';
import tab2 from 'assets/images/homepage/grafika2.png';
import tab2x2 from 'assets/images/homepage/grafika2@2x.png';
import tab3 from 'assets/images/homepage/grafika3.png';
import tab3x2 from 'assets/images/homepage/grafika3@2x.png';
import tab4 from 'assets/images/homepage/grafika4.png';
import tab4x2 from 'assets/images/homepage/grafika4@2x.png';
import tab5 from 'assets/images/homepage/grafika5.png';
import tab5x2 from 'assets/images/homepage/grafika5@2x.png';
import routes from 'data/globals/routes';

export default {
   header: [
      'Wprowadź ',
      <strong>cyfrowe porządki</strong>,
      <br />,
      ' w swojej firmie',
   ],
   featureCards: [
      {
         image: tile1,
         imageX2: tile1x2,
         color: colors.lightViolet,
         headerText: [
            'Unikaj błędów i oszczędzaj czas ',
            <strong>na produkcji</strong>,
         ],
         description:
            'Kontroluj proces produkcji i wprowadź system, który zautomatyzuje to, co teraz robisz ręcznie.',
         buttonLink: routes.production,
      },
      {
         image: tile2,
         imageX2: tile2x2,
         imagePosition: 'right',
         color: colors.lightGreen,
         headerText: [
            'Wprowadź cyfrowe formularze ',
            <strong>w swoich Laboratoriach</strong>,
         ],
         description:
            'Wprowadzaj wszystkie badania i oznaczenia na plan jednym kliknięciem i oszczędzaj czas na właściwą pracę.',
         buttonLink: routes.laboratory,
      },
      {
         image: tile3,
         imageX2: tile3x2,
         color: colors.lightBlue,
         headerText: [
            'Bądź na bieżąco ze zmianami ',
            <strong>na swojej budowie</strong>,
         ],
         buttonLink: routes.construction,
         description:
            'Zarządzaj swoją budową, dokumentuj  procesy, udostępnij wiedzę dla wszystkich osób zaangażowanych w projekt.',
      },
   ],
   questionTitle: [
      'LabControl to ',
      <strong>elastyczny i lekki system online,</strong>,
      ' który realnie rozwiązuje problemy wydajności w firmie',
   ],
   questionIllustration: questionIllustration,
   questionClouds: [
      {
         question: [
            'Czy przygotowanie sprawozdania dla klienta ',
            <strong>zajmuje Ci za dużo czasu?</strong>,
         ],
         questionLink: 'sprawozdania',
      },
      {
         question: [
            'Chciałbyś mieć',
            <strong> większy wpływ na planowanie </strong>,
            'pracy w firmie?',
         ],
         questionLink: 'harmonogramy',
      },
      {
         question: [
            <strong>Zdarzyło Ci się wycofanie sporej partii towaru</strong>,
            ' z powodu błędu na produkcji, który trafił do Ciebie zbyt późno?',
         ],
         questionLink: 'bledy',
      },
   ],
   capabilitiesCards: [
      {
         sectionId: 'harmonogramy',
         image: capabilities_image1,
         imageX2: capabilities_image1x2,
         height: 300,
         imgAlignment: 'flex-end',
         color: colors.lightViolet,
         headerText: [
            'Twórz i planuj własne ',
            <strong>harmonogramy pracy</strong>,
         ],
         description:
            'Odzwzoruj codzienne zadania w elektronicznych formularzach, a efektywność wprowadzania i prezentacji wyników wzrośnie kilkukrotnie.',
         buttonText: 'Czytaj dalej',
         buttonTo: {
            link: routes.functionalities,
            section: 'functionalities-reception',
         },
         reverseRow: false,
      },
      {
         sectionId: 'bledy',
         image: capabilities_image2,
         imageX2: capabilities_image2x2,
         height: 297,
         imgAlignment: 'flex-end',
         color: colors.lightBlue,
         headerText: [
            <strong>Eliminuj błędy i kontroluj</strong>,
            ' procesy w czasie rzeczywistym',
         ],
         description:
            'Śledź produkty i półprodukty od ich początkowego etapu produkcyjnego. Kontroluj finalną efektywność dzięki alertowaniu krtycznych sytuacji.',
         buttonText: 'Czytaj dalej',
         buttonTo: {
            link: routes.functionalities,
            section: 'functionalities-operations',
         },
         reverseRow: true,
      },
      {
         sectionId: 'sprawozdania',
         image: capabilities_image3,
         imageX2: capabilities_image3x2,
         height: 300,
         imgAlignment: 'flex-end',
         color: colors.lightGreen,
         headerText: [
            <strong>Wyklikaj sprawozdanie lub raport</strong>,
            ' w kilka sekund',
         ],
         description:
            'Wyświetl dane w zakładce raporty i wyślij do Zleceniodawcy. Wszystkie informacje są w jednym miejscu.',
         buttonText: 'Czytaj dalej',
         buttonTo: {
            link: routes.functionalities,
            section: 'functionalities-reports',
         },
         reverseRow: false,
      },
   ],
   functionalitiesTitle: ['Główne ', <strong>funkcjonalności</strong>],
   functionalitiesTabs: [
      {
         title: 'Harmonogram Pracy',
         text: {
            normal:
               ' jeszcze nigdy nie było tak proste. Przygotuj listę zadań, a potem wrzuć w harmonogram dnia i przypisz do konkretnej osoby w firmie.',
            bold: 'Planowanie pracy',
         },
         image: tab1,
         imageX2: tab1x2,
      },
      {
         title: 'Zarządzenia zasobami',
         text: {
            normal:
               ' sprzętów, stanów magazynowych, pracowników i kontoluj zasoby w firmie w jednym systemie.',
            bold: 'Zrób listy',
         },
         image: tab2,
         imageX2: tab2x2,
      },
      {
         title: 'Zarządzenia dokumentami',
         text: {
            normal:
               ' błyskawicznie wszystkie instrukcje, normy, wzory umów, i inne pliki, z których Twoi pracownicy korzystają wielokrotnie każdego dnia.',
            bold: 'Umieść i aktualizuj',
         },
         image: tab3,
         imageX2: tab3x2,
      },
      {
         title: 'Raportowanie',
         text: {
            normal:
               ' na własne potrzeby. Dodaj do ulubionych te, z których korzystasz najczęściej. Przesyłaj do Klienta w dogodnej formie.',
            bold: 'Twórz raporty',
         },
         image: tab4,
         imageX2: tab4x2,
      },
      {
         title: 'Tworzenie szablonów',
         text: {
            normal:
               ' do swojej firmy, a nie firmę do systemu. Stwórz własne szablony zadań, dokumentów, stanów magazynowych.',
            bold: 'Dopasuj cały system',
         },
         image: tab5,
         imageX2: tab5x2,
      },
   ],
   implementationTitle: [
      'Poznaj historię budowy produktu',
      <br />,
      'w ',
      <strong>KPT ScaleUp</strong>,
   ],
};
